
// Zodiac Options (Moinuddin Mansuri)
// export const ZodiacSigns = [
//   { name: "Aries", dateRange: "March 21 - April 19" },
//   { name: "Taurus", dateRange: "April 20 - May 20" },
//   { name: "Gemini", dateRange: "May 21 - June 20" },
//   { name: "Cancer", dateRange: "June 21 - July 22" },
//   { name: "Leo", dateRange: "July 23 - August 22" },
//   { name: "Virgo", dateRange: "August 23 - September 22" },
//   { name: "Libra", dateRange: "September 23 - October 22" },
//   { name: "Scorpio", dateRange: "October 23 - November 21" },
//   { name: "Sagittarius", dateRange: "November 22 - December 21" },
//   { name: "Capricorn", dateRange: "December 22 - January 19" },
//   { name: "Aquarius", dateRange: "January 20 - February 18" },
//   { name: "Pisces", dateRange: "February 19 - March 20" },
// ];


export const ZodiacSigns = [
  { name: "Aries", dateRange: "03 21 - 04 19" },
  { name: "Taurus", dateRange: "04 20 - 05 20" },
  { name: "Gemini", dateRange: "05 21 - 06 20" },
  { name: "Cancer", dateRange: "06 21 - 07 22" },
  { name: "Leo", dateRange: "07 23 - 08 22" },
  { name: "Virgo", dateRange: "08 23 - 09 22" },
  { name: "Libra", dateRange: "09 23 - 10 22" },
  { name: "Scorpio", dateRange: "10 23 - 11 21" },
  { name: "Sagittarius", dateRange: "11 22 - 12 21" },
  { name: "Capricorn", dateRange: "12 22 - 01 19" },
  { name: "Aquarius", dateRange: "01 20 - 02 18" },
  { name: "Pisces", dateRange: "02 19 - 03 20" }
];

// City Options (Moinuddin Mansuri)
export const citys = [
  { value: "Alabama", label: "AL" },
  { value: "Alaska", label: "AK" },
  { value: "Arizona", label: "AZ" },
  { value: "Arkansas", label: "AR" },
  { value: "California", label: "CA" },
  { value: "Colorado", label: "CO" },
  { value: "Connecticut", label: "CT" },
  { value: "Delaware", label: "DE" },
  { value: "District of Columbia", label: "DC" },
  { value: "Florida", label: "FL" },
  { value: "Georgia", label: "GA" },
  { value: "Hawaii", label: "HI" },
  { value: "Idaho", label: "ID" },
  { value: "Illinois", label: "IL" },
  { value: "Indiana", label: "IN" },
  { value: "Iowa", label: "IA" },
  { value: "Kansas", label: "KS" },
  { value: "Kentucky", label: "KY" },
  { value: "Louisiana", label: "LA" },
  { value: "Maine", label: "ME" },
  { value: "Maryland", label: "MD" },
  { value: "Massachusetts", label: "MA" },
  { value: "Michigan", label: "MI" },
  { value: "Minnesota", label: "MN" },
  { value: "Mississippi", label: "MS" },
  { value: "Missouri", label: "MO" },
  { value: "Montana", label: "MT" },
  { value: "Nebraska", label: "NE" },
  { value: "Nevada", label: "NV" },
  { value: "New Hampshire", label: "NH" },
  { value: "New Jersey", label: "NJ" },
  { value: "New Mexico", label: "NM" },
  { value: "New York", label: "NY" },
  { value: "North Carolina", label: "NC" },
  { value: "North Dakota", label: "ND" },
  { value: "Ohio", label: "OH" },
  { value: "Oklahoma", label: "OK" },
  { value: "Oregon", label: "OR" },
  { value: "Pennsylvania", label: "PA" },
  { value: "Rhode Island", label: "RI" },
  { value: "South Carolina", label: "SC" },
  { value: "South Dakota", label: "SD" },
  { value: "Tennessee", label: "TN" },
  { value: "Texas", label: "TX" },
  { value: "Utah", label: "UT" },
  { value: "Vermont", label: "VT" },
  { value: "Virginia", label: "VA" },
  { value: "Washington", label: "WA" },
  { value: "West Virginia", label: "WV" },
  { value: "Wisconsin", label: "WI" },
  { value: "Wyoming", label: "WY" },
];


// Formating phone number (Moinuddin Mansuri)
export const formatPhoneNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1,})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const countryCode = match[1] ? `+${match[1]}` : "";
    return `${countryCode} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return number;
};

// Formating Date (Moinuddin Mansuri)
export function formatDateString(dateString) {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

// Age Options (Moinuddin Mansuri)
export const options = [];
for (let i = 18; i <= 100; i++) {
  options.push({ value: i.toString(), label: i.toString() });
}

// Capital First Latter (Moinuddin Mansuri)
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Calcuate Age (Moinuddin Mansuri)
export function calculateAge(selectedDate) {
  if (selectedDate) {
    const birthDate = new Date(selectedDate);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    if (today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  }
  return "";
}

// Calculate Zodiac Sign (Moinuddin Mansuri)
// export function calculateZodiacSign(selectedDate) {
//   const date = new Date(selectedDate);
//   const month = date.getMonth() + 1;

//   for (const sign of ZodiacSigns) {
//     const range = sign.dateRange.split(" - ");
//     const startRange = range[0].split(" ");
//     const endRange = range[1].split(" ");

//     const startDate = new Date(`2005-${startRange[0]}-${startRange[1]}`);
//     const endDate = new Date(`2005-${endRange[0]}-${endRange[1]}`);

//     if (month === startDate.getMonth() + 1) {
//       if (date.getDate() >= startDate.getDate()) {
//         return sign.name;
//       }
//     } else if (month === endDate.getMonth() + 1) {
//       if (date.getDate() <= endDate.getDate()) {
//         return sign.name;
//       }
//     }
//   }

//   return "Unknown";
// }


export function calculateZodiacSign(selectedDate) {
  const date = new Date(selectedDate);
  const month = date.getMonth() + 1; // Months are 0-based, add 1 for comparison
  const day = date.getDate();

  for (const sign of ZodiacSigns) {
    const range = sign.dateRange.split(" - ");
    const startRange = range[0].split(" ");
    const endRange = range[1].split(" ");

    // Adjust month to 0-based index for JavaScript Date object
    const startMonth = parseInt(startRange[0], 10) - 1;
    const startDay = parseInt(startRange[1], 10);
    const endMonth = parseInt(endRange[0], 10) - 1;
    const endDay = parseInt(endRange[1], 10);

    // Use a fixed year for all comparisons to simplify logic
    const year = 2000; // Leap year to handle February 29

    const startDate = new Date(year, startMonth, startDay);
    const endDate = new Date(year, endMonth, endDay);

    // Adjust endDate to the next year if the zodiac sign spans across years
    if (startMonth > endMonth) {
      endDate.setFullYear(endDate.getFullYear() + 1);
    }

    // Handle dates for signs spanning across years
    const currentDate = new Date(year, month - 1, day);
    if (startMonth > endMonth && month < startMonth) {
      currentDate.setFullYear(currentDate.getFullYear() + 1);
    }

    if (currentDate >= startDate && currentDate <= endDate) {
      return sign.name;
    }
  }

  return "Unknown";
}
