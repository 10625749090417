import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Spinner,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { userForgetPassword, userForgetPasswordError, userForgetPasswordSuccess } from "../../store/actions";

import { otpUser, loginUser, otpErr, otpSuccess } from "../../store/actions";

// import images
// import logo from "assets/images/logo/bright-swap-logo.svg";
import logo from "../../assets/images/logo/bright-check-logo.svg"
import { BiArrowBack } from "react-icons/bi";

// Added by Moinuddin Mansuri
const VerifyEmail = (props) => {
  const dispatch = useDispatch();

  const { forgetSuccessData, forgetError, loading } = useSelector(
    (state) => state.ForgetPassword
  );

  const { loginUserData, error, loading2 } = useSelector((state) => state.Login);

  const forgetSuccessValue = JSON.parse(localStorage.getItem("verfyEmial"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      number: "",
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Please Enter Your OTP").length(6),
    }),
    onSubmit: (values) => {

      if (forgetSuccessValue.user.match == "loginOtp") {
        dispatch(otpSuccess(null));
        dispatch(otpErr(null));
        const verifyObj = {
          token: values.number,
          verificationId: forgetSuccessValue.data.verificationId || "",
          value: forgetSuccessValue.user.value,
          type: forgetSuccessValue.user.type,
          password: forgetSuccessValue.user.password,
          userType: "admin",
        };
        dispatch(otpUser(verifyObj, props.history));
      }
      else {
        dispatch(userForgetPasswordSuccess(null));
        dispatch(userForgetPasswordError(null));
        const verifyObj = {
          token: values.number,
          verificationId: forgetSuccessValue.data.verificationId || "",
          value: forgetSuccessValue.user.value,
          type: forgetSuccessValue.user.type,
          match: "verifyEmail",
        };
        dispatch(userForgetPassword(verifyObj, props.history));

      }
    },
  });

  const handleRsendOtp = () => {
    if (forgetSuccessValue.user.match == "loginOtp") {
      dispatch(otpSuccess(null));
      dispatch(otpErr(null));
      const allValues = {
        value: forgetSuccessValue.user.value,
        password: forgetSuccessValue.user.password,
        // userType: "admin",
        type: "email",
        match: "loginOtp"
      };
      dispatch(loginUser(allValues, props.history));
    } else {
      dispatch(userForgetPasswordSuccess(null));
      dispatch(userForgetPasswordError(null));
      const allValues = {
        value: forgetSuccessValue.user.value,
        type: "email",
        match: "forgetPwd",
      };
      dispatch(userForgetPassword(allValues, props.history));
    }



  };

  useEffect(() => {
    dispatch(otpSuccess(null));
    dispatch(otpErr(null));
    dispatch(userForgetPasswordSuccess(null));
    dispatch(userForgetPasswordError(null));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title> Verify Email | BrightCheck </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="py-5">
                    <Link to="/" className="auth-logo-light">
                      <img
                        src={logo}
                        alt="Bright Swap"
                        weight="191"
                      />
                    </Link>
                  </div>
                  <div className="py-2">
                    <h5 className="text-primary font-weight-700 text-uppercase letter-spacing-1">
                      VERIFY YOUR EMAIL
                    </h5>
                    <p className="mb-0 text-gray">
                      Please enter the validation code sent to
                    </p>
                    <p className="text-gray">{forgetSuccessValue.user.value}</p>
                  </div>
                  <div>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {forgetError ? (
                        <Alert color="danger">{forgetError}</Alert>
                      ) : null}
                      {forgetSuccessData ? (
                        <Alert color="success">
                          {forgetSuccessData.message}
                        </Alert>
                      ) : null}

                      {error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}
                      {loginUserData ? (
                        <Alert color="success">
                          {loginUserData.message}
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label mb-1">
                          Enter 6-digit code
                        </Label>
                        <Input
                          name="number"
                          className="form-control"
                          placeholder="Type Something"
                          type="text"
                          min={0}
                          onChange={(e) => {
                            var numbers = /^\d*\d*$/;
                            if (
                              e.target.value.length < 7 &&
                              e.target.value.match(numbers)
                            ) {
                              validation.handleChange(e);
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value.length < 7) {
                              validation.handleBlur(e);
                            }
                          }}
                          value={validation.values.number}
                          invalid={
                            validation.touched.number &&
                              validation.errors.number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.number &&
                          validation.errors.number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.number}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div
                        className="form-check ps-0"

                        onClick={() => handleRsendOtp()}
                      >
                        <label
                          className="form-check-label text-primary resend-code"
                          htmlFor="customControlInline"
                        >
                          Resend Code
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        {forgetSuccessValue.user.match != "loginOtp" ? loading ? (
                          <button
                            className="btn btn-primary w-md w-100"
                            type="submit"
                            disabled
                          >
                            <Spinner size="sm">Loading...</Spinner>
                            <span> Loading</span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Proceed
                          </button>
                        ) : ""}
                        {forgetSuccessValue.user.match == "loginOtp" ? loading2 ? (
                          <button
                            className="btn btn-primary w-md w-100"
                            type="submit"
                            disabled
                          >
                            <Spinner size="sm">Loading...</Spinner>
                            <span> Loading</span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Proceed
                          </button>
                        ) : ""}
                      </div>

                      <div
                        className="mt-5 mb-2 text-start"
                        style={{
                          marginLeft: "-19px",
                        }}
                      >
                        <Link
                          to="/login"
                          className="text-muted font-weight-500"
                        >
                          <div className="form-check text-left">
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                              onClick={() => handleRsendOtp}
                            >
                              <BiArrowBack
                                size={21}
                                style={{
                                  marginRight: "7px",
                                }}
                              />
                              Back to Login
                            </label>
                          </div>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(VerifyEmail);

VerifyEmail.propTypes = {
  history: PropTypes.object,
};
