
import React from "react";
const modal = {
  position: "fixed",
  zIndex: 1,
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
  overflow: "auto",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
};

const close = {
  position: "absolute",
  top: 15,
  right: 35,
  color: "#f1f1f1",
  fontSize: 40,
  fontWeight: "bold",
  cursor: "pointer",
};

const modalContent = {
  display: "flex",
  alignItems: "center",
  width: "85%",
  height: "100%",
  margin: "auto",
};

export const Modal = ({ onOpen, children }) => {
  return <div onClick={onOpen}> {children}</div>;
};

export const ModalContent = ({ onClose, children }) => {
  return (
    <div style={modal}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
      <div style={modalContent}>{children}</div>
    </div>
  );
};

// import React from 'react';
// import ZingChart from 'zingchart-react';

// class MyChart extends React.Component {
// 	constructor(){
// 		super()
// 		this.state={
// 			chartConfig:null
// 		}
// 	}
// componentDidMount(){
// 	setTimeout(() => {
// 		alert("dsjhfbjdh")
// 		this.setState({chartConfig:{
// 			type: "gauge",
// 			globals: {
// 			  fontSize: 25
// 			},
// 			plotarea: {
// 			  marginTop: 80
// 			},
// 			plot: {
// 			  size: '100%',
// 			  valueBox: {
// 				placement: 'center',
// 				text: '%v', //default
// 				fontSize: 35,
// 				rules: [
// 				  {
// 					rule: '%v >= 8',
// 					text: '%v<br>EXCELLENT'
// 				  },
// 				  {
// 					rule: '%v < 8 && %v > 6',
// 					text: '%v<br>Good'
// 				  },
// 				  {
// 					rule: '%v < 6 && %v > 4',
// 					text: '%v<br>Fair'
// 				  },
// 				  {
// 					rule: '%v <  4',
// 					text: '%v<br>Bad'
// 				  }
// 				]
// 			  }
// 			},
// 			tooltip: {
// 			  borderRadius: 5
// 			},
// 			scaleR: {
// 			  aperture: 180,
// 			  minValue: 1, // Adjusted min value to 1
// 			  maxValue: 10, // Adjusted max value to 10
// 			  step: 1, // Step of 1
// 			  center: {
// 				visible: false
// 			  },
// 			  tick: {
// 				visible: false
// 			  },
// 			  item: {
// 				offsetR: 0,
// 				rules: [{
// 				  rule: '%i == 9',
// 				  offsetX: 15
// 				}]
// 			  },
// 			  labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], // Labels from 1 to 10
// 			  ring: {
// 				size: 50,
// 				gradient: true, // Enable gradient
// 				gradientColors: ['#32CD32', '#FFA500'], // Gradient colors from green to orange,
// 				rules: [{
// 					  rule: '%v <= 8',
// 					  backgroundColor: 'blue'
// 					},
// 					{
// 					  rule: '%v > 8 && %v < 6',
// 					  backgroundColor: '#EF5350'
// 					},
// 					{
// 					  rule: '%v >= 6 && %v < 4',
// 					  backgroundColor: '#FFA726'
// 					},
// 					{
// 					  rule: '%v >= 4',
// 					  backgroundColor: '#29B6F6'
// 					}
// 				  ]

// 			  }
// 			},
// 			refresh: {
// 			  type: "feed",
// 			  transport: "js",
// 			  url: "feed()",
// 			  interval: 1500,
// 			  resetTimeout: 1000
// 			},
// 			series: [{
// 			  values: [6], // starting value
// 			  backgroundColor: 'black',
// 			  indicator: [10, 10, 10, 10, 0.75],
// 			  animation: {
// 				effect: 2,
// 				method: 1,
// 				sequence: 4,
// 				speed: 900
// 			  },
// 			}]
// 		  }})
// 	}, 1000);
// }
//   render() {
//     // Define your gauge chart configuration

//     return (
//       <div >
// 		{this.state.chartConfig&&<ZingChart data={this.state.chartConfig} id="myChart"/>}
//       </div>
//     );
//   }
// }

// export default MyChart;
