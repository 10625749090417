import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import { Row, Spinner, Col, CardBody, Card, Alert,Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { userForgetPassword } from "../../store/actions";
// import logo from "assets/images/logo/bright-swap-logo.svg";
import logo from "../../assets/images/logo/bright-check-logo.svg";
import { numSpeCReg, upLowCaseReg } from "helpers/Utils/allRegex";
import { BiArrowBack } from "react-icons/bi";

const ResetPassword = (props) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      conformPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(
          8,
          "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
        )
        .matches(
          upLowCaseReg,
          "Atleast Use One Uppercase And One Lowercase Letter"
        )
        .matches(numSpeCReg, "Atleast Use One Number And One Special Charcter"),
      conformPassword: Yup.string()
        .required("Please Enter Your Confirm Password")
        .oneOf([Yup.ref("password"), null], "Confirm Password Does not Match With New Password"),
    }),
    onSubmit: (values) => {
      const valueObj = {
        password: values.password,
        match: "resetPwd",
      };
      dispatch(userForgetPassword(valueObj, props.history));
    },
  });

  const { forgetError, loading } = useSelector((state) => state.ForgetPassword);

  return (
    <React.Fragment>
      <MetaTags>
        <title> Reset Password | BrightCheck </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="py-5">
                    <Link to="/" className="auth-logo-light">
                      <img
                        src={logo}
                        alt="Bright Swap"
                        weight="191"
                      />
                    </Link>
                  </div>
                  <div className="py-2">
                    <h5 className="text-primary font-weight-700 text-uppercase letter-spacing-1">
                      ENTER A NEW PASSWORD
                    </h5>

                    <p className="text-gray">
                      Please create a new password below.
                    </p>
                  </div>
                  <div>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {forgetError ? (
                        <Alert color="danger">{forgetError}</Alert>
                      ) : null}

                      <div className="mb-3 mb-3">
                        <Label className="form-label mb-1">New Password</Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            className="form-control"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />

                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-5">
                        <Label className="form-label mb-1">
                          Confirm New Password
                        </Label>
                        <Input
                          name="conformPassword"
                          className="form-control"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.conformPassword || ""}
                          invalid={
                            validation.touched.conformPassword &&
                            validation.errors.conformPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.conformPassword &&
                        validation.errors.conformPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.conformPassword}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-6 d-grid">
                        {loading ? (
                          <button
                            className="btn btn-primary w-md w-100"
                            type="submit"
                            disabled
                          >
                            <Spinner size="sm">Loading...</Spinner>
                            <span> Loading</span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Reset Password
                          </button>
                        )}
                      </div>
                      <div className="mt-5 mb-2 text-left">
                        <Link
                          to="/login"
                          className="text-muted font-weight-500"
                        >
                          <div className="form-check text-left">
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                               <BiArrowBack
                                size={21}
                                style={{
                                  marginRight: "7px",
                                }}
                              />
                              Back to Login
                            </label>
                          </div>
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object,
};
