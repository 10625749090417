import axios from "axios";

export const getOrderAllData = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/v2/order/get-all-orders`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const getOrderSingleData = async (id) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/v2/order/get-order-detail/${id}`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const deleteOrder = async (id) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/order/${id}`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const updateOrder = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/order/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};
